
.np-on-off {
    display: flex;
    flex-direction: column;
}

.np-on-off .radio-container {
    display: flex;
    flex-direction: row;
    background-color: #D7D7D7;
    border-radius: 20px;
    position: relative;
}

.np-on-off .radio-container .option-label {
    height: 24px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;
}
.np-on-off .radio-container .option-label .icon-container svg {
    width: 12px;
    height: 12px;
    opacity: 0.5;
}

.np-on-off .radio-container .floating-button {
    position: absolute;
    /* height: 100%; */
    border-radius: 20px;
    left: 0;
}
.np-on-off .radio-container:hover .floating-button {
    box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
}
.np-on-off .radio-container .floating-button.focused-easeInOut-motion {
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: 334ms;
}
