
.np-error-popup {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 24px;
    width: 30vw;
    border-radius: 5px;
    border: 1px solid #E1E1E1;
    box-shadow: 0 0px 12px rgb(0 0 0 / 15%);
}
.np-error-popup .image-container {
    display: flex;
    flex-basis: 18%;
    margin-right: 24px;
    max-height: 64px;
}
.np-error-popup .text-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.np-error-popup .text-container .title {
    margin-bottom: 9px;
}
.np-error-popup .text-container .cid {
    opacity: 0.6;
}

.np-error-popup .close {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
}
.np-error-popup .close:hover {
    box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
}
.np-error-popup .close svg {
    width: 12px;
    height: 12px;
    fill: currentColor;
}