
.admin-profiles-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: calc(var(--default-spacing) * 1.3);
}
.admin-profiles-title .image-container {
    flex-basis: 80px;
    margin-right: var(--default-spacing);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .admin-profiles-title .image-container {
        flex-basis: 15%;
    }
    .admin-profiles-screen {
        margin: 0 -9px;
    }
    .admin-profiles-screen .np-card {
        border-radius: 0;
        padding: 0;
    }
}