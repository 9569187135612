
.np-bread-crumb {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 23px;
    margin-top: -38px;
}
.np-bread-crumb .crumb-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
}
.np-bread-crumb .crumb-container .crumb {
    padding: 3px 12px;
    border-radius: 40px;
    cursor: pointer;
}
.np-bread-crumb .crumb-container .crumb:hover {
    box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
}
.np-bread-crumb .crumb-container .bc-separator {
    margin: 0 6px;
    opacity: 0.7;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .np-bread-crumb {
        margin-top: 0px;
    }
}