.new-transaction-card {
    cursor: pointer;    
    position: relative;
}
.new-transaction-card .card-content {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
} 
.new-transaction-card .card-content .text {
    text-align: left;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.new-transaction-card .card-content .text .new-tx-text {
    margin-bottom: 12px;
    font-weight: bold;
}
.new-transaction-card .card-content .image {
    display: flex;
    flex-basis: 18%;
}

.new-transaction-card .sorry-popup-content {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    box-sizing: border-box;
}

.new-transaction-card .sorry-popup-content .header {
    display: flex;
}
.new-transaction-card .sorry-popup-content .header .sorry {
    margin-bottom: 12px;
}
.new-transaction-card .sorry-popup-content .header .close-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
}
.new-transaction-card .sorry-popup-content .header .close-button-container svg {
    width: 12px;
    height: 12px;
    fill: currentColor;
}
.new-transaction-card .sorry-popup-content .body {
    display: flex;
}
.new-transaction-card .sorry-popup-content .body .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-right: 24px;
}
.new-transaction-card .sorry-popup-content .body .image-container {
    width: 60%;
    margin-right: 48px;
}