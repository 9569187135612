.agency-tx-payment-card.row {
    display: flex;
    align-items: stretch;
    font-size: var(--font-normal);
}

.agency-tx-payment-card .status {
    flex: 1;
    text-align: left;
}
.agency-tx-payment-card .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--default-spacing);
    border-left: 1px solid lightgray;
    padding-left: var(--default-spacing);
}

.agency-tx-payment-card .status .title {
    font-size: var(--font-large);
    border-left: 3px solid var(--color-complementary);
    padding: 3px var(--default-card-spacing);
    margin-bottom: var(--default-card-spacing);
}
.agency-tx-payment-card .status .info-text {
    margin-top: var(--default-card-spacing);
}

.agency-tx-payment-card .row {
    display: flex;
    margin: var(--default-card-spacing) 0;
}

.agency-tx-payment-card .row .np-labeled-value {
    flex: 1;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {

    .agency-tx-payment-card.row {
        flex-direction: column;
        align-items: flex-start;
    }
    .agency-tx-payment-card .status .title {
        background-color: var(--color-complementary-light);
        margin-bottom: var(--default-spacing);
    }
    .agency-tx-payment-card .button-container {
        margin: calc(var(--default-spacing) * 2) 0;
        border-left: none;
        padding-left: 0;
    }
}