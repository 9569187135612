.admin-monkey-title {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-bottom: calc(var(--default-spacing) * 1.3);
}

.admin-monkey-title .image-container {
    flex-basis: 80px;
    margin-right: var(--default-spacing);
}

.admin-monkey-screen .header {
    display: flex;
    align-items: center;
    background-color: var(--color-accent);
    margin: calc(-1 * var(--default-spacing));
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: var(--default-card-spacing) var(--default-card-spacing);
    color: white;
    font-weight: bold;
}

.admin-monkey-screen .header .actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.admin-monkey-screen .list {
    margin-top: 24px
}

/**************** RUNS *********************************/
.admin-monkey-screen .run {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d8d8d8;
    margin: 0px calc(-1 * var(--default-spacing));
    cursor: pointer;
    font-size: var(--font-normal);
    padding: 12px 20px
}

.admin-monkey-screen .run:hover {
    background-color: aliceblue;
}

.admin-monkey-screen .run.run-last {
    border-bottom: none;
    margin-bottom: -24px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.admin-monkey-screen .run .line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.admin-monkey-screen .run .image-container {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}

.admin-monkey-screen .run .image-container svg {
    fill: #787878;
}
.admin-monkey-screen .run .image-container.complementary svg {
    fill: var(--color-complementary)
}

.admin-monkey-screen .run .title-container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.admin-monkey-screen .run .time {
    font-size: var(--font-small);
}

.admin-monkey-screen .run .result-container {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.admin-monkey-screen .run .result-container .result-image {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-monkey-screen .run .result-container .result-image svg {
    width: 12px;
    height: 12px;
}

.admin-monkey-screen .run .result-container .result-failed {
    background-color: var(--color-complementary);
}

.admin-monkey-screen .run .result-container .result-succeeded {
    background-color: var(--color-accent);
}

.admin-monkey-screen .run .result-container .result-failed svg {
    fill: white;
}

.admin-monkey-screen .run .result-container .result-succeeded svg {
    fill: white;
}

.admin-monkey-screen .run .title-container {
    display: flex;
    margin-right: 12px;
    flex-basis: 40%;
}

.admin-monkey-screen .run .timer {
    display: flex;
    align-items: center;
}

.admin-monkey-screen .run .id-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}

.admin-monkey-screen .run .id-container .id {
    font-size: var(--font-small);
    font-family: monospace;
}

/**************** TASKS *********************************/
.admin-monkey-screen .run .tasks-container {
    border: 1px solid #787878;
    border-radius: 5px;
    padding: 0 var(--default-spacing);
    margin: var(--default-spacing) 0;
}

.admin-monkey-screen .run .tasks-container .task {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d8d8d8;
    margin: 0px calc(-1 * var(--default-spacing));
    cursor: pointer;
    font-size: var(--font-normal);
    padding: 18px 20px;
}

.admin-monkey-screen .run .tasks-container .task:hover {
    background-color: antiquewhite;
}

.admin-monkey-screen .run .tasks-container .task.task-last {
    border-bottom: none;
    /* margin-bottom: -24px; */
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.admin-monkey-screen .tasks-container .task .result-container .result-succeeded {
    background-color: #787878;
}

/**************** CHECKS *********************************/
.admin-monkey-screen .tasks-container .task .checks-container {
    border-left: 1px solid #787878;
    padding: 0 var(--default-spacing);
    margin: var(--default-spacing) 0;
}

.admin-monkey-screen .run .tasks-container .check {
    display: flex;
    flex-direction: column;
    margin: 0px calc(-1 * var(--default-spacing));
    font-size: var(--font-small);
    padding: 6px 20px;
}

.admin-monkey-screen .tasks-container .task .checks-container .title-container {
    display: flex;
    flex: 1;
    margin-left: 6px;
}

.admin-monkey-screen .tasks-container .task .checks-container .title-container .msg {
    font-style: italic;
}

.admin-monkey-screen .tasks-container .task .checks-container .result-container svg {
    fill: #787878;
}

.admin-monkey-screen .tasks-container .task .checks-container .result-container .result-succeeded {
    background-color: transparent;
    border: 1px solid #787878;
}

.admin-monkey-screen .tasks-container .task .checks-container .result-container .result-failed {
    background-color: transparent;
    border: 1px solid var(--color-complementary);
}

.admin-monkey-screen .tasks-container .task .checks-container .result-container .result-failed svg {
    fill: var(--color-complementary);
}

/**************** POPUP *********************************/
.new-monkey-run-popup {
    width: 50vw;
}

.new-monkey-run-popup .header {
    display: flex;
    align-items: center;
    margin: calc(-1 * var(--default-spacing)) calc(-1 * var(--default-spacing)) var(--default-spacing) calc(-1 * var(--default-spacing));
    padding: var(--default-card-spacing);
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom: 1px solid #e1e1e1;
    background-color: #e1e1e1;
}

.new-monkey-run-popup .header .title {
    margin-left: 12px;
}

.new-monkey-run-popup .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--default-spacing)
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .admin-monkey-title .image-container {
        flex-basis: 15%;
    }
}