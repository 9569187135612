.np-ocr-confidence {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-top: -24px;
    margin-bottom: -24px;
    margin-left: -24px;
    text-align: left;
}

.np-ocr-confidence .confidence-bar {
    width: 24px;
    height: 100%;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
}

.np-ocr-confidence .advice-container {
    margin-left: 24px;
}
.np-ocr-confidence .advice-container .user-message {
    font-size: var(--font-small);
    font-style: italic;
}
.np-ocr-confidence .advice-container .user-message .label {
    opacity: 0.6;
    font-style: normal;
}
/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {

    .np-ocr-confidence {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
    }

    .np-ocr-confidence .confidence-bar {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 12px;
    }
    .np-ocr-confidence .advice-container {
        margin-left: 12px;
    }
        
}