
.dispute-card {
    display: flex;
    flex-direction: column;
}
.dispute-card .header {
    display: flex;
    flex-direction: column;
}
.dispute-card .header .title {
    display: flex;
    flex: 1;
    justify-content: center;
}
.dispute-card .header .subtitle {
    font-size: var(--font-small);
    margin-top: 6px;
}
.dispute-card .header .explanation {
    font-size: var(--font-normal);
    margin-top: 12px;
}
.dispute-card .header .subtitle .dispute-status {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--color-complementary);
}
.dispute-card .header .loader-container svg {
    height: 24px;
    width: 24px;
}

.dispute-card .dispute-body {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}
.dispute-card .dispute-body .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}
.dispute-card .dispute-body .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding: 0 12px;
    border-right: 1px solid #e1e1e1;
}
.dispute-card .dispute-body .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding: 0 12px;
}
.dispute-card .dispute-body .left .list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
}
.dispute-card .dispute-body .right .actions-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 20px;
}
.dispute-card .dispute-body .right .action-container .message {
    display: flex;
    font-size: var(--font-small);
    text-align: left;
}
.dispute-card .dispute-body .right .action-container .success-image-container {
    margin-top: 24px;
}
.dispute-card .dispute-body .right .action-container .success-image-container svg {
    width: 50%;
    height: auto;
}
.dispute-card .dispute-body .right .action-container .label {
    font-size: var(--font-small);
    text-align: left;
    font-weight: bold;
    margin-top: 12px;
}
.dispute-card .dispute-body .right .action-container .result {
    margin-top: 24px;
}
.dispute-card .dispute-body .right .action-container .result .button-container {
    margin: 12px 0;
}
.dispute-card .dispute-body .right .action-container .loader-container {
    display: flex;
    flex: 1;
    margin-top: 18px;
    min-height: 50px;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
  
    .dispute-card .dispute-body {
        flex-direction: column;
    }

    .dispute-card .dispute-body .left {
        border-bottom: 1px solid #e1e1e1;
        border-right: none;
        padding: 12px 0px;
    }

    .dispute-card .dispute-body .right {
        padding: 12px 0;
    }
}