.np-admin-menu-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 15vw;
    height: 15vw;
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    margin: var(--default-spacing);
    padding: 1px;
    box-sizing: border-box;
}
.np-admin-menu-card:hover {
    box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
}

.focused-easeInOut-motion {
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: 334ms;
}

.np-admin-menu-card .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.np-admin-menu-card svg {
    fill: var(--color-accent);
    width: 30%;
    height: auto;
}

.np-admin-menu-card .text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: calc(var(--default-spacing) / 2) 0;
    color: var(--color-text-dark);
    height: 30%;
}

.np-admin-menu-card .text-container .title {
    margin-bottom: 6px;
    font-size: var(--font-large);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .np-admin-menu-card {
        width: calc((90vw - 3 * var(--default-spacing)) / 2);
        height: calc((90vw - 3 * var(--default-spacing)) / 2);
    }
    .np-admin-menu-card .image-container {
    }
}