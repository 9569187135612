.agency-tx-seller-wire .title {
    font-size: var(--font-large);
    padding-left: var(--default-card-spacing);
    border-left: 3px solid var(--color-primary);
    margin-bottom: var(--default-spacing);
    text-align: left;
}

.agency-tx-seller-wire .row {
    display: flex;
    align-items: center;
}

.agency-tx-seller-wire .row .np-labeled-value {
    margin-right: calc(var(--default-spacing) * 2);
}

.agency-tx-seller-wire .buttons-container {
    display: flex;
    margin-top: var(--default-spacing);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {

    .agency-tx-seller-wire .row {
        flex-direction: column;
        align-items: flex-start;
    }

    .agency-tx-seller-wire .row .np-labeled-value {
        margin-right: 0;
    }


}