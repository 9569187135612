.admin-button { 
    display: flex;
    flex-direction: row;
    margin: 6px 6px;
    font-size: var(--font-normal);
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}
.admin-button:hover { 
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}
.admin-button .count {
    background-color: var(--color-accent);
    border: 1px solid var(--color-accent);
    color: white;
    padding: 12px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
} 
.admin-button:hover .count {
    background-color: var(--color-accent) !important;
    border: 1px solid var(--color-accent) !important;
}
.admin-button.selected-true .count {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    font-weight: bold;
} 
.admin-button .title {
    border: 1px solid var(--color-accent);
    padding: 12px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
} 
.admin-button.selected-true .title {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: white;
    font-weight: bold;
}
.admin-button:hover .title {
    background-color: var(--color-accent) !important;
    border: 1px solid var(--color-accent) !important;
    color: white;
}

@media only screen and (max-width: 400px) {

    .admin-button { 
        margin: 3px 6px;
    }
    .admin-button .count {
        padding: 6px 12px;
    }   
    .admin-button .title {
        padding: 6px 12px;
    }   
}