
.past-tx-card-content {
    display: flex;
    flex-direction: row;
    margin: calc(-1 * var(--default-spacing) / 2);
    align-items: center;
    cursor: pointer;
}
.past-tx-card-content .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
}
.past-tx-card-content .left .name {
    margin-bottom: 6px;
}
.past-tx-card-content .left .price {}
.past-tx-card-content .right {}
.past-tx-card-content .right .label {
    margin-bottom: 3px;
    opacity: 0.7;
}
.past-tx-card-content .right .date {}