
.np-toggle-icon-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    outline: none;
    opacity: 0.3;
}
.np-toggle-icon-button:hover {
    opacity: 0.5;
    box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
}
.np-toggle-icon-button.on {
  opacity: 1;
}
.np-toggle-icon-button svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
}

/************+ RIPPLE *****************/
.np-toggle-icon-button .ripple-container {
    overflow: hidden;
    display: flex; 
    flex: 1;
}
.np-toggle-icon-button div.ripple {
    position: absolute; /* The absolute position we mentioned earlier */
    border-radius: 50%;
    width: 50%;
    height: 100%;
    transform: scale(0);
    animation: ripple 300ms linear;
    background-color: rgba(255, 255, 255, 0.7);
  }
  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }