.np-action-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    max-height: 140px;
}

.np-action-card .image-container {
    display: flex;
    margin-right: var(--default-spacing);
    flex-basis: 15%;
}

.np-action-card .text-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.np-action-card .text-container .title {
    margin-bottom: 6px;
    font-size: var(--font-xlarge);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .np-action-card .image-container {
        flex-basis: 14%;
    }
}