
.admin-new-rebate-code-screen {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.admin-new-rebate-code-screen.center {
    align-items: center;
    justify-content: center;
}
.admin-new-rebate-code-screen .loading-text {
    font-size: var(--font-large);
    margin-bottom: var(--default-spacing)
}

.admin-new-rebate-code-screen .np-select {
    min-width: 350px;
}
.admin-new-rebate-code-screen .np-text-input {
    width: 100%;
}

.admin-new-rebate-code-screen .buttons-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 34px;
    justify-content: center;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .admin-new-rebate-code-screen .np-select {
        min-width: 250px;
    }
    .admin-new-rebate-code-screen .buttons-container {
        margin-top: 12px
    }
}