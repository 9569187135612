.np-popup {
    position: absolute;
    z-index: 900;
    background-color: white;
    border: 1px solid #eaeaea;
    padding: 24px 12px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
    min-width: 130px;
}

.np-popup.validation {
    padding: 6px 12px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.np-popup.nowrap {
    white-space: nowrap;
}