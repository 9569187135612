.in-app-screen .page.single, .in-app-screen.default .page.single {
    display: flex;
    flex-direction: column;
    /* width: 35vw; */
    width: min(95vw, 600px);
}
.in-app-screen.wide .page.single {
    width: min(95vw, 900px);
}
.in-app-screen .page.single .content {
    display: flex;
    flex-direction: column;
    /* min-width: 600px; */
}
.in-app-screen .page.single .page-title {
    display: flex;
    text-align: left;
    margin-left: var(--default-spacing);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 800px) {
    .in-app-screen .page.single, .in-app-screen.default .page.single {
        width: min(95vw, 800px);
    }
    .in-app-screen.wide .page.single {
        width: min(95vw, 800px);
    }
}
@media only screen and (max-width: 400px) {
    .in-app-screen .page.single, .in-app-screen.default .page.single {
        width: 95vw;
    }
    .in-app-screen.wide .page.single {
        width: 95vw;
    }
}