.np-photo {
    position: relative;
    display: flex;
    height: 110px;
    width: 80%;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #b5b5b5;
    overflow: hidden;
    cursor: pointer;
    max-height: 100%;
}
.np-photo:hover {
    box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
}

.np-photo .uploaded-pic-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.np-photo img.uploaded-picture {
    position: absolute;
}
.np-photo .uploaded-pic-container .ok-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    opacity: 0.7;
    border-radius: 50%;
}
.np-photo .uploaded-pic-container .ok-container svg {
    width: 24px; 
    height: 24px;
    z-index: 2;
    fill: currentColor;
}
.np-photo .uploaded-pic-container .cancel-container {
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px; 
    background-color: rgba(0,0,0);
    opacity: 0.4;
    border-radius: 50%;
}
.np-photo .uploaded-pic-container .cancel-container:hover {
    box-shadow: 0 4px 6px rgb(0 0 0 / 60%);
    cursor: pointer;
}
.np-photo .uploaded-pic-container .cancel-container svg {
    width: 12px;
    height: 12px;
    z-index: 2;
    fill: white;
}
.np-photo .no-pic-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.np-photo .no-pic-container svg {
    width: 60px;
    height: 60px;
    fill: currentColor;
}

.np-photo-popup {
    box-shadow: 0 0px 15px rgb(0 0 0 / 80%);
    border-radius: 9px;
    overflow: hidden;
}
.np-photo-popup img {
    max-width: 80vw;
    max-height: 80vh;
}

.np-photo .button-container {
    position: absolute;
    top: 12px;
    right: 12px;
}

.np-photo-ocr-popup {
    box-shadow: 0 0px 15px rgb(0 0 0 / 80%);
    border-radius: 9px;
    overflow: scroll;
    max-width: 50vw;
    max-height: 50vh;
    background-color: white;
    padding: var(--default-spacing);
}
.np-photo-ocr-popup .subtitle {
    margin-bottom: var(--default-spacing);
    margin-top: 3px;
    opacity: 0.7;
}
.np-photo-ocr-popup .ocr-token {
    background-color: var(--color-accent);
    border-radius: 5px;
    padding: 6px 12px;
    margin: 6px 0;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .np-photo {
        height: 50px;
        width: 100px;
    }
    .np-photo .no-pic-container svg {
        width: 50%;
        height: 50%
    }
  }