.four-o-four {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
}

.four-o-four .image {
    display: flex;
    flex: 1;
    margin: 0 4vw;
}

.four-o-four .text-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

}