
.incoming-payments-card {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: left;
}
.incoming-payments-card .title {
    margin-bottom: 12px;
}
.incoming-payments-card .subtitle {
    margin-bottom: 6px;
}
.incoming-payments-card .opcode {
    opacity: 0.6;
    margin-bottom: 24px;
}


/******************+ LIST **************************/
.incoming-payments-card .list-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.incoming-payments-card .list-container .list-header {
    display: flex;
    flex-direction: row;
    opacity: 0.6;
    margin-bottom: 3px;
    padding: 0 6px;
} 
.incoming-payments-card .list-container .list-header .header {
    display: flex;
} 
.incoming-payments-card .list-container .list-header .header.date {
    flex-basis: 25%;
} 
.incoming-payments-card .list-container .list-header .header.paymentcode {
    flex: 1
} 
.incoming-payments-card .list-container .list-header .header.amount {
    text-align: right;
} 

.incoming-payments-card .list-container .list-body {
    display: flex;
    flex-direction: column;
} 
.incoming-payments-card .list-container .list-body .line {
    display: flex;
    flex-direction: row;
    margin: 6px 0;
    padding: 0 6px;
}
.incoming-payments-card .list-container .list-body .line .date {
    flex-basis: 25%;
} 
.incoming-payments-card .list-container .list-body .line .paymentcode {
    flex: 1
} 
.incoming-payments-card .list-container .list-body .line .amount {
    text-align: right;
} 
.incoming-payments-card .list-container .list-body .line .statement {
    opacity: 0.8;
} 

.incoming-payments-card .list-container .separator {
    display: flex;
    border-top: 1px solid #e1e1e1;
    margin: 9px 0;
}