
.np-card .agency-card-content .header {
    display: flex;
    align-items: center;
}

.np-card .agency-card-content .header .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--default-spacing);
}
.np-card .agency-card-content .header .icon svg {
    width: 32px;
    height: auto;
    fill: var(--color-accent-mkt);
}
.np-card .agency-card-content .header .title {
    font-size: var(--font-large);
}
.np-card .agency-card-content .card-content {
    margin-top: var(--default-spacing);
}