
.np-date-input {
    display: flex;
    flex-direction: column;
    margin: 12px 0;
    max-width: 180px;
}

.np-date-input .np-input-label {
    text-align: left;
    padding-left: 13px;
}

.np-date-input input[type='text'] {
    border-width: 1px;
    border-style: solid;
    background-color: white;
    border-radius: 5px;
    width: 100%;
    margin: 6px 0;
    height: 38px;
    padding-left: 12px;
    box-sizing: border-box;
    color: currentColor;
}

.np-date-input .border-ok {
    border-color: #e1e1e1;
}

.np-date-input .npti-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}
.np-date-input .npti-content .npti-button-container {
    margin-left: 12px;
}

.np-date-input .npti-content .text-input-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}
.np-date-input .text-input-container.shortest {
    width: 30%;
}
.np-date-input .text-input-container.short {
    width: 50%;
}
.np-date-input .text-input-container .feedback-icon {
    position: absolute;
    top: 14px;
    width: 20px;
    height: 20px;
    fill: currentColor;
}
.np-date-input .text-input-container .feedback-icon svg {
    height: 20px;
    width: 20px;
}

.np-date-input .text-input-container .info-icon {
    margin-left: 12px;
    cursor: pointer;
}
.np-date-input .text-input-container .info-icon svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
}

.np-date-input .info-popup-content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
.np-date-input .info-popup-content .info-popup-img {
    display: flex;
    flex-grow: 1;
    max-width: 128px;
}
.np-date-input .info-popup-content .info-popup-text {
    display: flex;
    flex-grow: 3;
    text-align: left;
    margin-left: 24px;
    align-items: center;
}