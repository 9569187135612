
.tx-docs-card {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.tx-docs-card .header {
    display: flex;
    flex-direction: row;
}
.tx-docs-card .header .title {
    display: flex;
    flex: 1;
}
.tx-docs-card .header .loader-container svg {
    height: 24px;
    width: 24px;
}

.tx-docs-card .doc-list-container {
    display: flex;
    flex-direction: column;
    margin-top: var(--default-spacing);
}
.tx-docs-card .doc-list-container .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
    cursor: pointer;
}
.tx-docs-card .doc-list-container .row:hover {
    background-color: #f3f3f3;
}
.tx-docs-card .doc-list-container .row .doc-container {
    display: flex;
    flex-basis: 10%;
    max-width: 20%;
    height: 32px;
    margin-right: 6px;
}
.tx-docs-card .doc-list-container .row .text-container {
    display: flex;
    flex: 1;
}
.tx-docs-card .doc-list-container .row .status-container .image-container svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
}

.tx-docs-card .doc-list-container .separator {
    margin: 6px 0;
    border-top: 1px solid #efefef;
}

/*********************++ POPUP *****************************/
.tx-doc-force-ocr {
    display: flex;
    flex-direction: column;
    max-width: clamp(600px, 40vw, 750px);
}
.tx-doc-force-ocr .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--default-spacing);
}
.tx-doc-force-ocr .header .image-container {
    display: flex;
    margin-right: var(--default-spacing);
}
.tx-doc-force-ocr .header .title {
    display: flex;
}
.tx-doc-force-ocr .desc {
    margin: 3px 0;
}
.tx-doc-force-ocr .desc.paragraph {
    margin-top: var(--default-spacing);
}
.tx-doc-force-ocr .button-container {
    display: flex;
    justify-content: center;
    margin-top: var(--default-spacing);
}
