/*************+ TITLE **************************/
.admin-transaction-detail-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}

.admin-transaction-detail-title .image-container {
    flex-basis: 80px;
    margin-right: 24px;
}

.admin-transaction-detail-screen .tx-id {
    text-align: left;
    padding-left: var(--default-card-spacing);
    font-size: var(--font-small);
    opacity: 0.7;
}

/**************++ CONTENT ************************/
.admin-transaction-detail-screen {
    display: flex;
    flex-direction: column;
}

.admin-transaction-detail-screen .np-card {
    margin: calc(var(--default-card-spacing) / 2) 0;
}

.admin-transaction-detail-screen .two-cols {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.admin-transaction-detail-screen .two-cols .col {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.admin-transaction-detail-screen .two-cols .col.left {
    margin-right: calc(var(--default-card-spacing) / 2);

}

.admin-transaction-detail-screen .two-cols .col.right {
    margin-left: calc(var(--default-card-spacing) / 2);
}

.admin-transaction-detail-screen .two-cols .col .card-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .admin-transaction-detail-screen {
        margin: 0 -9px;
    }
    .admin-transaction-detail-screen .np-card {
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
    }
    .admin-transaction-detail-screen .two-cols {
        flex-direction: column;
    }
    .admin-transaction-detail-screen .two-cols .col {
        margin: 0;
    }
    .admin-transaction-detail-screen .two-cols .col.left {
        margin-right: 0;
    }
}