.login-screen {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.login-screen .login-background {
    position: absolute;
    right: -20vw;
    bottom: -10vw;
    z-index: -1;
    width: 50vw;
}

.login-screen .login-container {
    width: clamp(400px, 45vw, 500px);
    border-radius: 5px;
    padding: 3vw 0 4vw 0;
    margin-top: 20vh;
    position: relative;
    border: 1px solid #e8e8e8;
    background-color: rgba(255, 255, 255, 0.9);
}

.login-screen .login-container .login-np {
    cursor: pointer;
}

.login-screen .login-container .login-np.image-container {
    margin-top: 20px;
}

.login-screen .login-container .login-np.image-container svg {
    fill: var(--color-primary);
    max-width: 30vw;
}

.login-screen .login-container .login-text {
    margin-top: clamp(6px, 3vw, 24px);
    margin-bottom: clamp(24px, 5vw, 48px);
}

.login-screen .login-container .login-button-container {
    display: flex;
    justify-content: center;
    margin: 12px 0;
    max-height: 52px;
}

.login-screen .login-container .login-button-container .login-button {
    display: flex;
    flex-direction: row;
    background-color: white;
    align-items: center;
    border-radius: 5px;
    padding: clamp(6px, 0.9vw, 9px) 0;
    min-width: 60%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.login-screen .login-container .login-button-container .login-button.fb {
    background-color: #1b77f3;
}

.login-screen .login-container .login-button-container .login-button svg {
    width: 32px;
}

.login-screen .login-container .login-button-container .login-button:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

.login-screen .login-container .login-button-container .login-button .icon-container {
    display: flex;
    width: 80px;
    justify-content: center;
    /* padding: 0 clamp(9px, 2vw, 24px) 0 12px; */
}

.login-screen .login-container .login-button-container .login-button .icon-container.fb .icon {
    fill: white;
}

.login-screen .login-container .login-button-container .login-button .login-button-text {
    display: flex;
}

.login-screen .login-container .auth-failed-message {
    margin-top: 2vw;
}

/************* LOADER *************************/
.login-screen .loader-container {
    margin: calc(var(--default-spacing) * 2) 0;
}

.login-screen .loader-container svg {
    color: var(--color-primary);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .login-screen .login-container {
        width: 100vw;
        border-radius: 0;
        padding: 0 10vw;
        margin-top: 10vh;
        position: relative;
        border: none;
    }

    .login-screen .login-container .login-np.image-container {
        margin-top: 0;
        margin-bottom: 10vh;
    }

    .login-screen .login-container .login-np.image-container svg {
        max-width: 50vw;
    }

    .login-screen .login-container .login-button-container {
        max-height: 20vh;
    }

    .login-screen .login-container .login-button-container .login-button {
        width: 100%;
        margin: 0 var(--default-spacing);
        padding: var(--default-spacing);
    }

    .login-screen .login-container .login-button-container .login-button .icon-container {
        width: 10vw;
    }

    .login-screen .login-container .login-button-container .login-button .icon-container svg {
        width: 10vw;
    }

    .login-screen .login-container .login-button-container .login-button .login-button-text {
        margin-left: var(--default-spacing);
        font-size: var(--font-large);
    }

    .login-screen .login-background {
        display: none;
    }

    .login-screen .login-container .login-text {
        font-size: var(--font-normal);
    }
}