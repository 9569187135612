/*************+ TITLE **************************/
.admin-profile-detail-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}

.admin-profile-detail-title .image-container {
    flex-basis: 80px;
    margin-right: 24px;
}


/*************+ CONTENT **************************/
.admin-profile-detail-screen .profile-buttons-container {
    margin: 0 12px;
}

.admin-profile-detail-screen .profile-buttons-container .button-container {
    margin: 12px 0;
}

.admin-profile-detail-screen .ocr-container {
    display: flex;
    flex-direction: row;
}

.admin-profile-detail-screen .ocr-container .left {
    display: flex;
    flex: 1;
}

.admin-profile-detail-screen .ocr-container .right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 30%;
}

.admin-profile-detail-screen .ocr-container .right .profile-verified-status {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.admin-profile-detail-screen .ocr-container .right .profile-verified-status .status-left {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.profile-incomplete-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
}

.profile-incomplete-card .left {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.profile-incomplete-card .left .title {
    margin-bottom: 3px;
}

.profile-incomplete-card .right {
    display: flex;
    flex-basis: 30%;
    justify-content: flex-end;
}

/************+ POPUP ****************/
.reject-profile-popup {
    width: 40vw;
}
.reject-profile-popup .button-container {
    margin-top: 24px;
}

.delete-profile-popup .warn {
    font-size: var(--font-xlarge);
    text-align: center;
    margin-bottom: 24px;
    text-transform: uppercase;
    color: var(--color-complementary);
}
.delete-profile-popup .title {
    font-size: var(--font-large);
    text-align: center;
    margin-bottom: 24px;
}
.delete-profile-popup .buttons-container {
    display: flex;
    flex-direction: row;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    
    .admin-profile-detail-screen {
        margin: 0 -9px;
    }
    .admin-profile-detail-screen .ocr-container {
        flex-direction: column;
    }

    .admin-profile-detail-screen .ocr-container .right {
        flex: 1;
        margin-top: 12px;
    }
    .admin-profile-detail-screen .np-card {
        border-radius: 0;
    }
    /************+ POPUP ****************/
    .reject-profile-popup {
        width: 80vw;
    }
    .reject-profile-popup .button-container {
        margin-top: 24px;
    }
}
