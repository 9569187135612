
.dashboard-profile-create-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 100px;
}

.dashboard-profile-create-button .image-container {
    display: flex;
    flex-basis: 15%;
    flex-shrink: 1;
    margin-right: var(--default-spacing);
}
.dashboard-profile-create-button .image-container svg {
    width: 100%;
    height: auto;
}
.dashboard-profile-create-button .text-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: left;
}
.dashboard-profile-create-button .text-container .desc {
    margin-top: 6px;
}

@media only screen and (max-width: 400px) {
    .dashboard-profile-create-button {
        max-height: 90px;
    }
  
  }