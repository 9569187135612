.tx-payout-card .payout-amt {
    margin-top: calc(-1 * var(--default-card-spacing));
    margin-bottom: var(--default-spacing);
}
.tx-payout-card .beneficiary-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: var(--default-spacing);
}

.tx-payout-card .payout-composition {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    padding-bottom: var(--default-spacing);
}

.tx-payout-card .payout-composition .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 12px;
    font-weight: bold;
}