.np-card-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-left: 3px solid var(--color-primary);
    padding-left: var(--default-card-spacing);
    color: var(--color-text);
    text-align: left;
}

.np-card-message .row {
    display: flex;
    align-items: center;
}

.np-card-message.no-border {
    border: none !important;
    padding-left: 0 !important;
}

.np-card-message.bck-complementary {
    background-color: #FFE4BF;
    padding-top: var(--default-card-spacing);
    padding-bottom: var(--default-card-spacing);
    padding-right: var(--default-card-spacing);
}

.np-card-message.primary {
    border-left-color: var(--color-primary);
}

.np-card-message.complementary {
    border-left-color: var(--color-complementary);
}

.np-card-message.accent {
    border-left-color: var(--color-accent);
}

.np-card-message.accent-mkt {
    border-left-color: var(--color-accent-mkt);
}

.np-card-message .text {
    flex: 1;
    margin-right: var(--default-card-spacing);
    text-align: left;
}
.np-card-message .date {
    font-size: var(--font-xsmall);
    opacity: 0.7;
    text-align: left;
}

.np-card-message .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-accent-mkt);
    border-radius: 50%;
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: var(--default-card-spacing);
}
.np-card-message .icon.warn {
    background-color: var(--color-complementary);
}

.np-card-message .icon svg {
    width: 20px;
    height: auto;
    fill: white;
}

.np-card-message .instructions {
    font-size: smaller;
    opacity: 0.8;
    margin-top: 3px;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {

    .np-card-message .icon {
        width: 24px;
        height: 24px;
        min-height: 24px;
        max-height: 24px;
        min-width: 24px;
        max-width: 24px;
    }
    
    .np-card-message .icon svg {
        width: 16px;
    }


}