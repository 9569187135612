.profile-card {
    cursor: pointer;
}
.profile-card .progress-and-icon-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: var(--default-spacing) 0 0 0;
}
.profile-card .progress-bar-container {
    display: flex;
    flex: 1;
    margin-right: 24px;
}

.profile-card .title {
    text-align: left;
}

.profile-card .additional-info {
    margin-top: var(--default-spacing);
    text-align: left;
}

/*******************+ MOBILE ***********************/
@media only screen and (max-width: 400px) {
    .profile-card .progress-and-icon-container {
        margin-bottom: var(--default-spacing);
    }
}