.np-toolbar {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: var(--header-height);
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    align-items: center;
}

.np-toolbar .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 2vw;
    cursor: pointer;
    height: 100%;
}

.np-toolbar .logo svg {
    height: calc(var(--header-height) / 2);
    width: auto;
}

.np-toolbar .menu {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
}

.np-toolbar .menu .item-container {
    display: flex;
    flex-direction: column;
    margin: 0 2vw;
}

.np-toolbar .menu .item-container .menu-highlight {
    border-bottom: 1px solid currentColor;
    display: flex;
    padding: 0 12px;
}

.np-toolbar .logout-container {
    margin-right: 2vw;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {

    .np-toolbar {
        padding: 0 2vw;
    }
    
    .np-toolbar .menu .item-container {
        transform: scale(0.8);
        margin: 0;
    }

    .np-toolbar .logo svg {
        height: calc(var(--header-height) / 2.5);
    }
}