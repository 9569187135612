@media only screen and (max-width: 400px) {
    .screen {
        margin: 0 -9px;
    }

    .screen .np-card {
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .day-user-sessions-screen .np-card,
    .no-padding-cards .np-card {
        padding: 0;
    }
}