.profile-progress-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-right: 24px;
    cursor: pointer;
}
.profile-progress-container .step-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    margin: 0 2px;
}
.profile-progress-container .step-container.greyed {
    color: #E4E4E4;
}
.profile-progress-container .step-container.reduced-opacity {
    background-color: currentColor;
    opacity: 0.5;
}

.profile-progress-container .step-container .step {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: currentColor;
    height: 14px;
}
.profile-progress-container .step-container .step.first {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.profile-progress-container .step-container .step.last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.profile-progress-container .step-container .avatar {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 3px solid currentColor;
    border-radius: 50%;
    right: -20px;
    top: -13px;
    background-color: white;
    box-sizing: border-box;
    z-index: 2;
}
.profile-progress-container .step-container .avatar svg {
    width: 18px;
    fill: currentColor;
}
/************** POPUP ***************/
.np-progress-bar-popup-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
}
.np-progress-bar-popup-content .image {
    width: 32px;
    height: 32px;
    margin-right: var(--default-spacing);
}
.np-progress-bar-popup-content .image svg {
    width: 32px;
    height: 32px;
    fill: currentColor;
}
