.tranlog-card {
    display: flex;
    flex-direction: column;
}

.tranlog-card .two-col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start !important;
}
.tranlog-card .two-col .left {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.tranlog-card .two-col .right {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
}
.tranlog-card .card-title {
    margin-bottom: 24px;
}
