
.tx-header-card {
    display: flex;
    flex-direction: row;
}
.tx-header-card .tx-header-box {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}