.transactions-list-card {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.transactions-list-card .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    margin: -24px -24px 0 -24px;
    padding: 24px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.transactions-list-card .header .title {
    display: flex;
    flex: 1;
}
.transactions-list-card .header .icon-container {
    display: flex;
    margin-left: 12px;
}

.transactions-list-card .todo-buttons-container {
    margin-top: 12px;
}
.transactions-list-card .todo-buttons-container .label {
    font-size: var(--font-small);
    text-align: left;
    margin-bottom: -24px;
}
.transactions-list-card .admin-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 12px 0;
}
.transactions-list-card .admin-buttons.bo-todo {
    margin: 0 -24px 12px -24px;
    background-color: #E1EEFF;
    padding: 40px 24px 24px 24px
}

@media only screen and (max-width: 400px) {

    .transactions-list-card .header {
        margin: -24px -24px 0 -24px;
        padding: 24px 24px 0 24px;
    }
    .transactions-list-card .admin-buttons.bo-todo {
        padding: 32px 12px 12px 12px; 
        margin: 0 -12px 12px -12px;
    }
}