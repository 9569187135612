.np-card {
    display: flex;
    position: relative;
    /* flex-direction: row; */
    flex-direction: column;
    border-width: 1px;
    border-style: solid;
    border-radius: var(--default-card-border-radius);
    padding: var(--default-spacing);
    margin: var(--default-card-spacing) 0;
}
.np-card.padding-reduced {
    padding: calc(var(--default-spacing) / 2); 
}
.np-card.spacing-small {
    margin: calc(var(--default-card-spacing) / 3) 0;
}

.np-card:hover {
    box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
}
.np-card.action {
    cursor: pointer;
}
.np-card.column {
    flex-direction: column;
}
.np-card.border-normal {
    border-color: #eaeaea;
}
.np-card.fill-white {
    background-color: white;
}
.np-card.highlight.top {
    border-top: 5px solid #e1e1e1;
}
.np-card.highlight.left {
    border-left: 5px solid #e1e1e1;
}
.np-card.highlight.top.highlight-primary {
    border-top-color: var(--color-primary);
}
.np-card.highlight.top.highlight-complementary {
    border-top-color: var(--color-complementary);
}
.np-card.highlight.left.highlight-primary {
    border-left-color: var(--color-primary);
}
.np-card.highlight.left.highlight-complementary {
    border-left-color: var(--color-complementary);
}
.focused-easeInOut-motion {
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: 334ms;
}

.np-card .card-title {
    margin-bottom: 24px;
}

.np-card .card-bottom-greyout {
    background-color: #f3f3f3;
    margin-left: calc(-1 * var(--default-spacing));
    margin-right: calc(-1 * var(--default-spacing));
    margin-bottom: calc(-1 * var(--default-spacing));
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-top: var(--default-spacing)
}

.np-card .card-content {
    display: flex;
}
.np-card .card-content.row {
    flex-direction: row;
}
.np-card .card-content.row.space-evenly {
    justify-content: space-evenly;
}

.precard-title {  
    font-size: var(--font-small);
    text-align: left;
    padding-left: 12px;
    margin-bottom: -7px;
    opacity: 0.8;
}

/******************************************************
* FLOATING EDIT BUTTON
*****************************************************/
.np-card .card-edit-button {
    position: absolute;
    right: var(--default-spacing);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .np-card .card-title {
        margin-bottom: 12px;
    }
    .np-card .card-content.row.mobile-col {
        flex-direction: column;
    }
}