
.np-cool-radio-multi {
    display: flex;
    flex-direction: column;
    margin: 12px 0;
}

.np-cool-radio-multi .np-input-label {
    text-align: left;
    padding-left: 13px;
}

.np-cool-radio-multi .radio-container {
    display: flex;
    flex-direction: row;
    background-color: #D7D7D7;
    height: 32px;
    /* width: 15vw; */
    border-radius: 20px;
    margin: 6px 0;
    position: relative;
}

.np-cool-radio-multi .radio-container .option-label {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;
}

.np-cool-radio-multi .radio-container .floating-button {
    position: absolute;
    height: 32px;
    width: 50%;
    border-radius: 20px;
    left: 0;
}
.np-cool-radio-multi .radio-container:hover .floating-button {
    box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
}
.np-cool-radio-multi .radio-container .floating-button.focused-easeInOut-motion {
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: 334ms;
}

.np-cool-radio-multi .notes-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 13px;
    opacity: 0.7;
}