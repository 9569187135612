.tx-key-details-card {
    display: flex;
    flex-direction: column;
}

.tx-key-details-card .two-col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start !important;
}
.tx-key-details-card .two-col .left {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.tx-key-details-card .two-col .right {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
}
.tx-key-details-card .card-title {
    margin-bottom: 24px;
}

.tx-key-details-card .button-container {
    margin-top: 12px;
}

.acube-invoice-data-container {
    display: flex;
    max-width: 50vw;
    max-height: 70vh;
    overflow-y: scroll;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {

    .acube-invoice-data-container {
        max-width: 95vw;
        font-size: var(--font-small);
    }
  
  }