.in-app-screen {
    display: flex;
    flex-direction: row;
    padding-top: calc(var(--header-height) + 48px);
    justify-content: center;
    background-color: var(--color-background);
    min-height: calc(100vh - var(--header-height));
}
.in-app-screen .page {
    display: flex;
    flex-direction: column;
    width: clamp(650px, 60vw, 1020px);
    /* width: 60vw; */
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .in-app-screen {
        padding-top: calc(var(--header-height) + 12px);
    }
    .in-app-screen .page {
        width: 100vw;
    }
}