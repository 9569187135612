
.np-icon-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.np-icon-button-container .label {
  margin-left: 12px;
  min-width: 80px;
}
.np-icon-button-container:hover .label {
  color: #0B929E;
}

.np-icon-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    outline: none;
    padding: 9px;
    min-width: 40px;
    min-height: 40px;
    box-sizing: border-box;
}
.np-icon-button-container:hover .np-icon-button {
    box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
}
.np-icon-button.large {
  padding: 12px;
}
.np-icon-button.small {
  padding: 4px;
  min-width: 32px;
  min-height: 32px;
}
.np-icon-button svg {
  width: 16px;
  height: auto;
  fill: currentColor;
}
.np-icon-button.no-fill svg {
  width: 24px;
}
.np-icon-button.small svg {
  width: 14px;
}

/************+ RIPPLE *****************/
.np-icon-button .ripple-container {
    overflow: hidden;
    display: flex; 
    flex: 1;
}
.np-icon-button div.ripple {
  position: absolute; /* The absolute position we mentioned earlier */
  border-radius: 50%;
  width: 50%;
  height: 100%;
  transform: scale(0);
  animation: ripple 300ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}
@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
  .np-icon-button {
    min-width: 24px;
    min-height: 24px;
    padding: 4px;
  }
  .np-icon-button svg {
      width: 12px;
      height: auto;
  }
  .np-icon-button.no-fill svg {
    width: 24px;
  }
}