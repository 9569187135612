.np-card-outside-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 24px;
}

.np-card-outside-header .title-filters {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.np-card-outside-header .title {
    margin-right: 24px;
    margin-bottom: 12px;
    font-size: var(--font-normal);
}

.np-card-outside-header .flexed {
    flex: 1;
}
.np-card-outside-header .filter {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.np-card-outside-header .filter .counter {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: var(--font-xsmall);
    color: white;
    margin-right: 6px;
}
.np-card-outside-header .filter select {
    background: white;
    border: 1px solid #d7d7d7;
    border-radius: 20px;
    padding: 0 12px;
    font-size: var(--font-small);
    font-family: "Poppins";
}

.np-card-outside-header .filter select option {
    background-color: white;
}


/*******************+ MOBILE ***********************/
@media only screen and (max-width: 400px) {
    .np-card-outside-header {
        margin: -12px 12px 12px 12px;
        align-items: flex-end;
    }
    
}