.rebates-list-card {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.rebates-list-card .search-container {
    min-width: 240px;
}

.rebates-list-card .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    margin: -24px -24px 0 -24px;
    padding: 24px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.rebates-list-card .header .title {
    display: flex;
    flex: 1;
}
.rebates-list-card .header .icon-container {
    display: flex;
    margin-left: 12px;
}

.rebates-list-card .todo-buttons-container {
    margin-top: 12px;
}
.rebates-list-card .todo-buttons-container .label {
    font-size: var(--font-small);
    text-align: left;
    margin-bottom: -24px;
}
.rebates-list-card .admin-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 12px 0;
}
.rebates-list-card .admin-buttons.bo-todo {
    margin: 0 -24px 12px -24px;
    background-color: #E1EEFF;
    padding: 40px 24px 24px 24px
}
.rebates-list-card .admin-buttons .admin-button { 
    display: flex;
    flex-direction: row;
    margin: 6px 6px;
    font-size: var(--font-normal);
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}
.rebates-list-card .admin-buttons .admin-button:hover { 
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}
.rebates-list-card .admin-buttons .admin-button .count {
    background-color: var(--color-accent);
    border: 1px solid var(--color-accent);
    color: white;
    padding: 12px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
} 
.rebates-list-card .admin-buttons .admin-button:hover .count {
    background-color: var(--color-accent) !important;
    border: 1px solid var(--color-accent) !important;
}
.rebates-list-card .admin-buttons .admin-button.selected-true .count {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    font-weight: bold;
} 
.rebates-list-card .admin-buttons .admin-button .title {
    border: 1px solid var(--color-accent);
    padding: 12px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
} 
.rebates-list-card .admin-buttons .admin-button.selected-true .title {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: white;
    font-weight: bold;
}
.rebates-list-card .admin-buttons .admin-button:hover .title {
    background-color: var(--color-accent) !important;
    border: 1px solid var(--color-accent) !important;
    color: white;
}

@media only screen and (max-width: 400px) {
    
    .rebates-list-card .search-container {
        min-width: 150px;
    }

    .rebates-list-card .header {
        margin: -24px -24px 0 -24px;
        padding: 24px 24px 0 24px;
    }
    .rebates-list-card .admin-buttons.bo-todo {
        padding: 32px 12px 12px 12px; 
        margin: 0 -12px 12px -12px;
    }
    .rebates-list-card .admin-buttons .admin-button { 
        margin: 3px 6px;
    }
    .rebates-list-card .admin-buttons .admin-button .count {
        padding: 6px 12px;
    }   
    .rebates-list-card .admin-buttons .admin-button .title {
        padding: 6px 12px;
    }   
}