.np-complex-list {
    display: flex;
    flex-direction: column;
}

.np-complex-list .list-item {
    display: flex;
    flex-direction: column;
    font-size: var(--font-normal);
    text-align: left;
    border-bottom: 1px solid #efefef;
    padding: 12px 0;
}

.np-complex-list .main-line {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
.np-complex-list .detail-line {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-left: 44px;
}
.np-complex-list .detail-line.actions {
    margin-top: 24px;
    margin-bottom: 24px;
}

.np-complex-list .list-item .low-relevance {
    opacity: 0.7;
}

.np-complex-list .list-item .tag {
    background-color: var(--color-primary);
    padding: 2px 12px;
    border-radius: 20px;
    font-size: var(--font-small);
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
}

.np-complex-list .list-item .tag.company {
    background-color: var(--color-complementary);
}

.np-complex-list .list-item .tag.operator {
    background-color: var(--color-accent);
}

.np-complex-list .list-item .icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 6px;
}

.np-complex-list .list-item .icon svg {
    width: 20px;
    height: auto;
    fill: rgba(0, 0, 0, 0.65);
}

.np-complex-list .list-item .icon.small svg {
    width: 16px;
}

.np-complex-list .list-item .icon.company svg {
    fill: var(--color-complementary);
}

.np-complex-list .list-item .icon.private svg {
    fill: var(--color-primary);
}

.np-complex-list .list-item .icon.operator svg {
    fill: var(--color-accent);
}
.np-complex-list .list-item .icon.agency svg {
    fill: var(--color-accent-mkt);
}

.np-complex-list .list-item .icon.accentmkt svg {
    fill: var(--color-accent-mkt);
}

.np-complex-list .list-item .col {
    display: flex;
    flex-direction: column;
}

.np-complex-list .list-item .row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.np-complex-list .hfill {
    display: flex;
    flex: 1;
}

.np-complex-list .cell.labeled {
    display: flex;
    flex-direction: column;
}

.np-complex-list .cell .label {
    font-size: var(--font-xsmall);
    opacity: 0.6;
}
.np-complex-list .expander svg {
    fill: var(--color-accent);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {

    .np-complex-list .list-item {
        white-space: nowrap;
        padding: 6px 0;
    }

    .np-complex-list .detail-line {
        margin-top: 0;
    }

    .np-complex-list .list-item .cell {
        padding: 6px 0;
    }

    .np-complex-list .list-item .hfill {
        flex: 0;
    }

    .np-complex-list .desktop-only {
        display: none !important;
    }
}