.profile-for-tx-card {
    display: flex;
    flex-direction: column;
}

.profile-for-tx-card .two-col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start !important;
}
.profile-for-tx-card .two-col .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 6px;
}
.profile-for-tx-card .two-col .right {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
}
.profile-for-tx-card .card-title {
    margin-bottom: 3px;
}
.profile-for-tx-card .id {
    font-size: var(--font-xsmall);
    text-decoration: underline;
    color: var(--color-primary);
    cursor: pointer;
    margin-bottom: var(--default-spacing);
}
.profile-for-tx-card .id:hover {
    color: var(--color-accent-mkt);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
}