.tx-payments-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.tx-payments-card .tx-payment-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 24px;
}
.tx-payments-card .tx-payment-text-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    margin: 6px 0;
}
.tx-payments-card .tx-payment-text-container .texts-container {
    display: flex;
    flex-direction: column;
    padding-top: 3px;
}
.tx-payments-card .tx-payment-text-container .texts-container .text-container {
    text-align: left;
}

.tx-payments-card .tx-payment-box .button-container {
    margin: 24px 12px 0 12px;
    justify-content: center;
    display: flex;
    flex: 1;
}

.tx-payments-card .image-container {
    margin-right: 12px;
}
.tx-payments-card .image-container svg {
    fill: currentColor;
    width: 24px;
    height: 24px;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .tx-payments-card .tx-payment-text-container .texts-container {
        padding-top: 5px;
        font-size: var(--font-large)
    }
}