.master-data-card {
    display: flex;
    flex-direction: row;
    text-align: left;
    flex-wrap: wrap;
}

.master-data-card .left {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.master-data-card .right {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
}

.master-data-card .right.large {
    flex-basis: 50%;
}

.master-data-card .card-title {
    margin-bottom: 24px;
}

.master-data-card .body-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.master-data-card .body-container .column {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: var(--default-spacing);
}

.master-data-buttons-container {
    display: flex;
    flex-direction: row;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .master-data-card .right.large {
        flex-basis: 100%;
        margin-top: var(--default-spacing);
        border-top: 1px solid #efefef;
        padding-top: var(--default-spacing);
    }
}