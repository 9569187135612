.np-doc-uploader-container .label {
    text-align: left;
    padding-left: 13px;
    margin-bottom: 6px;
}

.np-doc-uploader {
    position: relative;
    display: flex;
    height: 7vw;
    width: 12vw;
    min-width: 180px;
    min-height: 100px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
}
.np-doc-uploader.shadow-on-mouseover:hover {
    box-shadow: 0 4px 6px rgb(0 0 0 / 15%);
    cursor: pointer;
}

.np-doc-uploader .html-input-container {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.focused-easeInOut-motion {
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: 334ms;
}

.np-doc-uploader .upload-illustration {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.np-doc-uploader .upload-illustration .image-container svg {
    width: 48px;
    height: 48px;
    fill: currentColor;
}

.np-doc-uploader .uploaded-pic-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.np-doc-uploader img.uploaded-picture {
    position: absolute;
}
.np-doc-uploader .uploaded-pic-container .ok-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    opacity: 0.7;
    border-radius: 50%;
}
.np-doc-uploader .uploaded-pic-container .ok-container svg {
    width: 24px; 
    height: 24px;
    z-index: 2;
    fill: currentColor;
}
.np-doc-uploader .uploaded-pic-container .cancel-container {
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px; 
    background-color: rgba(0,0,0);
    opacity: 0.4;
    border-radius: 50%;
}
.np-doc-uploader .uploaded-pic-container .cancel-container:hover {
    box-shadow: 0 4px 6px rgb(0 0 0 / 60%);
    cursor: pointer;
}
.np-doc-uploader .uploaded-pic-container .cancel-container svg {
    width: 12px;
    height: 12px;
    z-index: 2;
    fill: white;
}

