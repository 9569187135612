/************+ FOOTER ****************/
.footer {
    height: var(--footer-height);
    max-height: var(--footer-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2vw;
    border-top: 1px solid #efefef;
    /* overflow: hidden; */
}

.footer .logo svg {
    height: 2vw;
    width: auto;
    fill: #005eff;
}

.footer .footer-items {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-evenly;
    font-size: var(--font-menu-item);
}

.footer .footer-items .footer-item {
    cursor: pointer;
}

.footer .footer-items .footer-item:hover {

    color: var(--color-accent-mkt)
}

.footer .copyright {
    font-size: var(--font-menu-item);
}

/**********************************************/
/************+ FOR MOBILE ONLY ****************/
/**********************************************/
@media only screen and (max-width: 500px) {

    /************+ FOOTER ****************/
    .footer {
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        padding-bottom: 2vh;
        height: auto;
        max-height: none;
    }
    .footer .footer-items {
        font-size: var(--font-large);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .footer .footer-item {
        min-width: 40vw;
        text-align: center;
        margin: 1vh 0;
    }

    .footer .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 3vh 0;
    }
    .footer .logo svg {
        height: 5vh;
    }
    .footer .copyright {
        text-align: center;
        margin: 2vh 0;
        font-size: var(--font-main-text);
    }
        
}