@keyframes pleggit-anim-loader {
    from {
        width: 32px;
        height: 32px;
    }

    to {
        width: 50px;
        height: 50px;
    }
}

.pleggit-anim-loader {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pleggit-anim-loader .logo-container {
    animation-name: pleggit-anim-loader;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}