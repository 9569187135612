.profiles-list-card {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.profiles-list-card .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    margin: -24px -24px 0 -24px;
    padding: 24px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.profiles-list-card .header .title {
    display: flex;
    flex: 1;
}
.profiles-list-card .header .icon-container {
    display: flex;
    margin-left: 12px;
}
.profiles-list-card .admin-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 12px 0;
}

/*******************+ MOBILE ***********************/
@media only screen and (max-width: 400px) {
}