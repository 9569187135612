.dashboard-right {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    padding-top: 12px;
}

.dashboard-right .section-label {
    opacity: 0.5;
    text-align: left;
    padding-left: 24px;
    margin-bottom: 6px;
}

.dashboard-right .past-tx-none {
    opacity: 0.5;
    text-align: left;
    padding-left: 24px;
}