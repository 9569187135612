.admin-users-screen .card-title {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: var(--font-normal);
    text-align: left;
    padding: 0 var(--default-spacing);
    margin-bottom: calc(-0.5 * var(--default-card-spacing))
}
.admin-users-screen .card-title .hfill {
    flex: 1;
}
.admin-users-screen .card-title .count {
    background-color: var(--color-primary);
    border-radius: 50%;
    min-width: 24px;
    height: 24px;
    color: white;
    font-size: var(--font-small);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 400px) {
    .admin-users-screen {
        margin: 0 -9px;
    }

    .admin-users-screen .np-card {
        border-radius: 0;
        padding: 3px;
    }

    .admin-users-screen .card-title {
        margin-bottom: 0;
    }
}