
.admin-pup-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: calc(var(--default-spacing) * 1.3);
}

.admin-pup-title .image-container {
    display: flex;
    flex-basis: 16%;
    margin-right: var(--default-spacing);
}

.admin-pup-title .title-container {
    display: flex;
    flex: 1;
}

.admin-pup-screen {
    display: flex;
    flex-direction: column;
}
.admin-pup-screen .input-container {
    margin: 6px 0;
}