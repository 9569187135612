.in-app-screen .page .two-col {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    /* flex: 1; */
}
.in-app-screen .page .two-col .main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 6px;
    /* width: 70%; */
    min-width: max(70%, 600px);
}
.in-app-screen .page .two-col .side-content {
    margin: 0 6px;
    display: flex;
    flex: 1;
    min-width: 250px;
}
.in-app-screen .page .page-title {
    text-align: left;
    margin-left: var(--default-spacing);
}


/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .in-app-screen .page .two-col .main-content {
        min-width: 90vw;
    }
}