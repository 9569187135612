
.np-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: rgba(0,0,0,0.7);
}
.np-checkbox .box {
    width: 18px;
    height: 18px;
    border: 2px solid currentColor;
    border-radius: 5px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.np-checkbox .box svg {
    width: 12px;
    height: auto;
}

.np-checkbox.edit-true {
    color: var(--color-primary);
    cursor: pointer;
}
.np-checkbox.edit-true:hover {
    opacity: 0.5;
}