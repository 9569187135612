.identity-doc-card {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.identity-doc-card .card-title {
    margin-bottom: 24px;
}
.identity-doc-card .card-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.identity-doc-card .card-content .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}
